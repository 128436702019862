export const UNITTYPES = {
  phuong: {
    name: 'UBND cấp Phường/Xã',
    level: 'ward',
  },
  quan: {
    name: 'UBND cấp Quận/Huyện',
    level: 'district',
  },
  tinh: {
    name: 'UBND cấp Tỉnh/Thành',
    level: 'province',
  },
  sgd: {
    name: 'Sở Giáo dục và Đào tạo',
    level: 'province',
  },
  pgd: {
    name: 'Phòng Giáo dục và Đào tạo',
    level: 'district',
  },
  mn: {
    name: 'Mầm non',
    expand: ['lcmn123', 'lcmn1'],
    level: 'ward',
  },
  th: {
    name: 'Tiểu học',
    expand: ['lcmn123', 'lc123', 'lc12'],
    level: 'ward',
  },
  thcs: {
    name: 'Trung học cơ sở',
    expand: ['lcmn123', 'lc23', 'lc123', 'lc12'],
    level: 'ward',
  },
  thpt: {
    name: 'Trung học phổ thông',
    expand: ['lcmn123', 'lc23', 'lc123'],
    level: 'ward',
  },
  lcmn123: {
    name: 'Liên cấp MN, 1, 2, 3',
    level: 'ward',
  },
  lcmn1: {
    name: 'Liên cấp MN, 1',
    level: 'ward',
  },
  lc12: {
    name: 'Liên cấp 1, 2',
    level: 'ward',
  },
  lc23: {
    name: 'Liên cấp 2, 3',
    level: 'ward',
  },
  lc123: {
    name: 'Liên cấp 1, 2, 3',
    level: 'ward',
  },
};

export const SCHOOLTYPES = ['cl', 'dl', 'tt'];
export const SCHOOL_LEVELS = [
  'mn',
  'th',
  'thcs',
  'thpt',
  'lc12',
  'lc23',
  'lc123',
  'lcmn1',
  'lcmn123',
];

export const GOVERNING_TYPES = ['sgd', 'pgd', 'phuong', 'quan', 'tinh'];

export const LICENSES: CustomObject<CustomObject<boolean>> = {
  standard: {
    all: true,
  },
  full: {
    edubot: true,
  },
  special: {
    edubot: true,
  },
  trial: {
    all: true,
  },
  demo: {
    all: true,
  },
};

export const EXPORT_UNIT_SERVERS = ['abot', 'accounts'];
