export const GRADES: [
  '0-6thangtuoi',
  '6-12thangtuoi',
  '12-18thangtuoi',
  '18-24thangtuoi',
  '24-36thangtuoi',
  'nhatre',
  'maugiao',
  'tieuhoc'
] = [
  '0-6thangtuoi',
  '6-12thangtuoi',
  '12-18thangtuoi',
  '18-24thangtuoi',
  '24-36thangtuoi',
  'nhatre',
  'maugiao',
  'tieuhoc',
];
