import type { FirebaseError } from 'firebase/app';
import _ from 'lodash';
import type { ApiProblems } from 'types';

export default (error: any) => {
  if ((error as FirebaseError).code) {
    return `Firebase error: ${error.code}`;
  }

  if ((error as ApiProblems).kind) {
    if (error?.error?.response?.data?.errors && _.isArray(error.error.response.data.errors)) {
      return `${error?.error?.message}: ${error.error.response.data.errors
        .map((e: Error) => e.message)
        .join('. ')}`;
    }
    return `${error?.error?.message}: ${error?.error?.response?.data ?? 'Unkown'}`;
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (error.errors) {
    return Object.values(error.errors).join(', ');
  }

  return 'Unkown error';
};
