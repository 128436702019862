import type { Cities } from 'features/catalogs/types';
import type { Document, Product, Unit } from 'features/products/types';
import _ from 'lodash';
import { backendService } from 'services';
import formatError from 'utils/formatError';

export const getProducts = async () => {
  const result = await backendService.post<(Product & { _id: string })[]>('/products/get');
  if (result.kind === 'ok') {
    const products = result.data;

    const elibotCities = await new Promise<Cities>((resolve) => {
      backendService
        .post<Cities>('/elibot/cities', {})
        .then((elibotResult) => {
          if (elibotResult.kind === 'ok') {
            resolve(elibotResult.data);
          } else {
            resolve({});
          }
        })
        .catch(() => {
          resolve({});
        });
    });
    const elibotIndex = products.findIndex(({ _id }) => _id === 'elibot');
    _.set(products, [elibotIndex, 'resources', 'cities'], elibotCities);
    return products ?? [];
  }
  throw Error(formatError(result));
};

export const getQIUnits = async (product: string, sgd: string, schoolYear: number) => {
  const result = await backendService.post<CustomObject<string>>(`/qi/${product}/units`, {
    sgd,
    schoolYear,
  });
  if (result.kind === 'ok') {
    return result.data ?? [];
  }
  throw Error(formatError(result));
};

export const updateProduct = async ({
  product,
  data,
}: {
  product: string;
  data: Partial<Product>;
}) => {
  if (product === 'elibot') {
    _.unset(data, 'resources');
  }
  const result = await backendService.post<boolean>('/products/update', {
    product,
    data,
  });
  if (result.kind === 'ok') {
    return true;
  }
  throw Error(formatError(result));
};

export const getDocuments = async (product: string) => {
  const result = await backendService.post<(Omit<Document, 'id'> & { _id: string })[]>(
    '/products/documents',
    {
      product,
    }
  );
  if (result.kind === 'ok') {
    return result.data ?? [];
  }
  throw Error(formatError(result));
};

export const downloadDocument = async (product: string, id: string) => {
  const result = await backendService.download('/products/downloadDocument', {
    id,
    product,
  });

  if (result.kind === 'ok') {
    return result.data;
  }
  throw Error(formatError(result));
};

export const updateDocument = async ({ id, ...data }: Partial<Document>) => {
  const result = await backendService.post<boolean>('/products/updateDocument', {
    id,
    data: Object.keys(data).length ? data : undefined,
  });

  if (result.kind === 'ok') {
    return result.data;
  }
  throw Error(formatError(result));
};

export const analyzeSyncUnits = async (product: string) => {
  const result = await backendService.post<Unit[]>('/legacy/analyze', {
    product,
  });
  if (result.kind === 'ok') {
    return result.data ?? [];
  }
  throw Error(formatError(result));
};

export const syncUnits = async ({ product, units }: { product: string; units: Unit[] }) => {
  const result = await backendService.post<Unit[]>('/legacy/syncUnits', {
    product,
    units,
  });
  if (result.kind === 'ok') {
    return result.data ?? [];
  }
  throw Error(formatError(result));
};
