import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'styles/index.less';
import 'locales/i18n';
import 'moment/locale/vi';
import 'utils/datetime';

import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Persister, PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ConfigProvider } from 'antd';
import viVN from 'antd/lib/locale/vi_VN';
import App from 'App';
import { initializeApp } from 'firebase/app';
import FontFaceObserver from 'fontfaceobserver';
import ErrorBoundary from 'pages/ErrorBoundary';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from 'reportWebVitals';
import { createIDBPersister } from 'services/persistQueryClient';
import { configureAppStore } from 'store';

initializeApp(
  ['development', 'qa'].includes(process.env.REACT_APP_STAGE ?? '')
    ? {
        apiKey: 'AIzaSyDOQ4LoxE9kRvDIGj7INm-_vwz6Dts4l3s',
        authDomain: 'xbot-accounts-dev.firebaseapp.com',
        databaseURL: 'https://xbot-accounts-dev.firebaseio.com',
        projectId: 'xbot-accounts-dev',
        storageBucket: 'xbot-accounts-dev.appspot.com',
        messagingSenderId: '562168795958',
        appId: '1:562168795958:web:91350064cbe23c5d9a9997',
      }
    : {
        apiKey: 'AIzaSyAIwY1lw-_1vWGAzY6W8Pceds-hxdmmpsg',
        authDomain: 'xbot-accounts.firebaseapp.com',
        databaseURL: 'https://xbot-accounts.firebaseio.com',
        projectId: 'xbot-accounts',
        storageBucket: 'xbot-accounts.appspot.com',
        messagingSenderId: '494095097208',
        appId: '1:494095097208:web:a3e79a3bb261d44307fc8d',
      }
);
// Observe loading of Inter (to remove 'Inter', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Inter', {});

// When Inter is loaded, add a font-family using Inter to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

const store = configureAppStore();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const persister = createIDBPersister() as Persister;

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ErrorBoundary>
        <ConfigProvider locale={viVN}>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{
              persister,
              maxAge: Infinity,
              buster: '1',
            }}
          >
            <App />
            <ReactQueryDevtools position="bottom-right" />
          </PersistQueryClientProvider>
        </ConfigProvider>
      </ErrorBoundary>
    </React.StrictMode>
  </Provider>
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
