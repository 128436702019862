import { InputNumber, InputNumberProps } from 'antd';

const IntegerInput = (props: InputNumberProps) => (
  <InputNumber
    {...props}
    formatter={(value) => {
      const reg = /^[0-9]+$/;
      if (reg.test(value as string)) {
        return `${value}`;
      }
      return '';
    }}
  />
);

export default IntegerInput;
