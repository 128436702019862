import { selectUserInformation } from 'features/user/store/selectors';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

type Props = {
  children: JSX.Element;
};

const AuthorizedRoute = ({ children }: Props) => {
  const location = useLocation();

  const user = useSelector(selectUserInformation);

  if (!user || !Object.keys(user.roles ?? {}).length) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default AuthorizedRoute;
