export const BACKEND = (() => {
  switch (process.env.REACT_APP_STAGE) {
    case 'development':
      return 'http://localhost:5053';
    case 'qa':
      return 'https://qaaio.back3nd.xyz';
    default:
      return 'https://aios.xbot.com.vn';
  }
})();

export const RESOURCES = 'https://resources.xbot.com.vn';
