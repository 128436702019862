import { Input } from 'antd';
import type { FilterDropdownProps } from 'antd/lib/table/interface';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';

const SearchInput = ({ selectedKeys, setSelectedKeys, confirm }: FilterDropdownProps) => {
  const { t } = useTranslation();

  return (
    <Input.Search
      placeholder={t(translations.actions.search) ?? 'search'}
      enterButton="OK"
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => {
        confirm();
      }}
      onSearch={() => {
        confirm();
      }}
    />
  );
};

export default SearchInput;
