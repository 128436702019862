import type { PayloadAction } from '@reduxjs/toolkit';
import type { SignInPayload, UserInformationState, UserState } from 'features/user/types';
import { createSlice } from 'utils/@reduxjs/toolkit';

export const initialState: UserState = {
  authorized: false,
};

const slice = createSlice({
  name: 'userStore',
  initialState,
  reducers: {
    fetchInformation(state, action: PayloadAction<UserInformationState | null>) {
      state.information = action.payload ?? undefined;
      state.authorized = true;
    },
    signedIn(state, _action: SignInPayload) {
      state.information = undefined;
      state.authorized = false;
    },
    signOut(state) {
      state.information = undefined;
    },
  },
});

export const { actions, name: key, reducer } = slice;
