import type { Cities, Food, Resources } from 'features/catalogs/types';
import { backendService } from 'services';
import formatError from 'utils/formatError';

export const getCities = async () => {
  const result = await backendService.post<Cities>('/api/cities');
  if (result.kind === 'ok') {
    return result.data ?? {};
  }
  throw Error(formatError(result));
};

export const getResources = async () => {
  const result = await backendService.post<Resources>('/api/resources');
  if (result.kind === 'ok') {
    return result.data ?? {};
  }
  throw Error(formatError(result));
};

export const getFoods = async () => {
  const result = await backendService.post<CustomObject<Food>>('/api/foods');
  if (result.kind === 'ok') {
    return result.data ?? {};
  }
  throw Error(formatError(result));
};

export const updateFood = async (data: { id: string; data?: Food }) => {
  const result = await backendService.graphql<boolean>(
    '/database/foods',
    'updateFood',
    `
    mutation updateFood($id: String!, $data: FoodInput) {
      updateFood(id: $id, data: $data)
    }
  `,
    data
  );
  if (result.kind === 'ok') {
    return true;
  }
  throw Error(formatError(result));
};

export const updateCity = async (data: {
  id: string;
  name?: string;
  type?: string;
  province?: string;
  district?: string;
  removed?: boolean;
  elibot?: string;
}) => {
  const result = await backendService.graphql<boolean>(
    '/database/cities',
    'updateInformation',
    `
      mutation updateInformation($data: [CityInput]!) {
        updateInformation(data: $data)
      }
    `,
    {
      data: [data],
    }
  );
  if (result.kind === 'ok') return true;
  throw Error(formatError(result));
};
