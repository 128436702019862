import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { DAY_ONLY, ONCE_ONLY } from 'configs';
import { getCities, getFoods, getResources, updateCity, updateFood } from 'features/catalogs/apis';
import { t } from 'i18next';
import { translations } from 'locales/translations';
import formatError from 'utils/formatError';

export const useCities = () =>
  useQuery(['catalogs', 'cities'], () => getCities(), {
    ...DAY_ONLY,
    onError: (error) => {
      notification.error({
        message: t(translations.errors.queryFailed).toString(),
        description: formatError(error),
      });
    },
  });

export const useResources = () =>
  useQuery(['catalogs', 'resources'], () => getResources(), {
    ...ONCE_ONLY,
    onError: (error) => {
      notification.error({
        message: t(translations.errors.queryFailed).toString(),
        description: formatError(error),
      });
    },
  });

export const useFoods = (enabled: boolean) =>
  useQuery(['catalogs', 'foods'], () => getFoods(), {
    ...DAY_ONLY,
    enabled,
    onError: (error) => {
      notification.error({
        message: t(translations.errors.queryFailed).toString(),
        description: formatError(error),
      });
    },
  });

export const useUpdateFood = (onSuccess?: (data?: boolean) => void) => {
  const queryClient = useQueryClient();
  return useMutation(updateFood, {
    onError: (error) => {
      notification.error({
        message: t(translations.errors.updateFailed).toString(),
        description: formatError(error),
      });
    },
    onSuccess: (data) => {
      notification.success({
        message: t(translations.notify).toString(),
        description: t(translations.success.updateSuccess).toString(),
      });
      queryClient.invalidateQueries(['catalogs', 'foods']);
      if (onSuccess) onSuccess(data);
    },
  });
};

export const useUpdateCity = (onSuccess?: (data?: boolean) => void) => {
  const queryClient = useQueryClient();
  return useMutation(updateCity, {
    onError: (error) => {
      notification.error({
        message: t(translations.errors.updateFailed).toString(),
        description: formatError(error),
      });
    },
    onSuccess: (data) => {
      notification.success({
        message: t(translations.notify).toString(),
        description: t(translations.success.updateSuccess).toString(),
      });
      queryClient.invalidateQueries(['catalogs', 'cities']);
      if (onSuccess) onSuccess(data);
    },
  });
};
