import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { MINUTES } from 'configs';
import {
  analyzeSyncUnits,
  getDocuments,
  getProducts,
  syncUnits,
  updateDocument,
  updateProduct,
} from 'features/products/apis';
import type { Product } from 'features/products/types';
import { t } from 'i18next';
import { translations } from 'locales/translations';
import formatError from 'utils/formatError';

export const useProducts = () =>
  useQuery(['products'], getProducts, {
    ...MINUTES,
    onError: (error) => {
      notification.error({
        message: t(translations.errors.queryFailed).toString(),
        description: formatError(error),
      });
    },
    select: (data) => {
      const products: CustomObject<Product> = {};
      data.forEach(({ _id, ...values }) => {
        products[_id] = values;
      });
      return products;
    },
  });

export const useUpdateProduct = (onSuccess?: (data?: boolean) => void) => {
  const queryClient = useQueryClient();
  return useMutation(updateProduct, {
    onError: (error) => {
      notification.warning({
        message: t(translations.errors.updateFailed).toString(),
        description: formatError(error),
      });
    },
    onSuccess: (data) => {
      notification.success({
        message: t(translations.notify).toString(),
        description: t(translations.success.updateSuccess).toString(),
      });
      queryClient.invalidateQueries(['products']);
      if (onSuccess) onSuccess(data);
    },
  });
};

export const useDocuments = (product: string) =>
  useQuery(['products', 'documents', product], () => getDocuments(product), {
    staleTime: 30 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    enabled: !!product,
    select: (data) => data.map(({ _id, ...values }) => ({ id: _id, ...values })),
    onError: (error) => {
      notification.warning({
        message: t(translations.errors.queryFailed).toString(),
        description: formatError(error),
      });
    },
  });

export const useUpdateDocument = (product: string, onSuccess?: (data?: boolean) => void) => {
  const queryClient = useQueryClient();
  return useMutation(updateDocument, {
    onError: (error) => {
      notification.warning({
        message: t(translations.errors.updateFailed).toString(),
        description: formatError(error),
      });
    },
    onSuccess: (data) => {
      notification.success({
        message: t(translations.notify).toString(),
        description: t(translations.success.updateSuccess).toString(),
      });
      queryClient.invalidateQueries(['products', 'documents', product]);
      if (onSuccess) onSuccess(data);
    },
  });
};

export const useSyncUnits = (product: string) =>
  useQuery(['products', 'analyzeSyncUnits', product], () => analyzeSyncUnits(product), {
    staleTime: 20 * 60 * 1000,
    cacheTime: 20 * 60 * 1000,
    onError: (error) => {
      notification.error({
        message: t(translations.errors.queryFailed).toString(),
        description: formatError(error),
      });
    },
  });

export const useDoSyncUnits = (product: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(syncUnits, {
    onError: (error) => {
      notification.warning({
        message: t(translations.errors.syncFailed).toString(),
        description: formatError(error),
      });
    },
    onSuccess: () => {
      notification.success({
        message: t(translations.notify).toString(),
        description: t(translations.success.syncSuccess).toString(),
      });
      queryClient.invalidateQueries(['products', 'analyzeSyncUnits', product]);
      if (onSuccess) onSuccess();
    },
  });
};
