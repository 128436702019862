const ONE_DAY = 1000 * 60 * 60 * 24;

export const ONCE_ONLY = {
  cacheTime: Infinity,
  staleTime: Infinity,
};
export const DAY_ONLY = {
  cacheTime: ONE_DAY,
  staleTime: ONE_DAY,
};

export const MINUTES = {
  cacheTime: 5 * 60 * 1000,
  staleTime: 5 * 60 * 1000,
};
