import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { createAjvValidator, JSONEditor } from 'vanilla-jsoneditor';

type Props = {
  initValue: any;
  onChange: (value: any) => void;
  schema?: any;
  readOnly?: boolean;
};

const Editor = ({ initValue, onChange, schema, readOnly }: Props) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const refEditor = useRef<JSONEditor | null>(null);

  useEffect(() => {
    if (refContainer.current) {
      refEditor.current = new JSONEditor({
        target: refContainer.current,
        props: {
          content: {
            json: initValue,
          },
          readOnly,
          onChange: (content, prev, status) => {
            if (!_.get(status, 'contentErrors.validationErrors', []).length) {
              onChange(_.get(content, 'json', {}));
            }
          },
        },
      });

      if (schema) {
        refEditor.current.updateProps({
          validator: createAjvValidator({ schema }),
        });
      }
    }

    return () => {
      if (refEditor.current) {
        refEditor.current.destroy();
        refEditor.current = null;
      }
    };
  }, [initValue, onChange, readOnly, schema]);

  return <div ref={refContainer} />;
};

export default Editor;
