import { FilterFilled } from '@ant-design/icons';
import {
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Timeline,
  Typography,
} from 'antd';
import Waiting from 'components/Waiting';
import { translations } from 'locales/translations';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { backendService } from 'services';
import formatError from 'utils/formatError';

type Log = {
  time: number;
  message: string;
  ref?: string;
  user?: string;
};
type Props = {
  unit: string;
  type: string;
  handleCancel: () => void;
};

type Filter = {
  user?: string;
  rangeTime?: Moment[];
  action?: string;
};

const actionOptions = [
  {
    value: 'addMail',
    label: 'Thêm Email',
  },
  {
    value: 'removeMail',
    label: 'Xoá Email',
  },
  {
    value: 'license',
    label: 'Sửa hạn dùng',
  },
];
const Logs = ({ unit, type, handleCancel }: Props) => {
  const { t } = useTranslation();

  const [logs, setLogs] = useState<Log[]>([]);
  const [handling, setHandling] = useState(false);
  const [customFilter, setCustomFilter] = useState<Filter>({});
  const [logsFilter, setLogsFilter] = useState<Log[]>([]);
  const [form] = Form.useForm<Filter>();

  useEffect(() => {
    (async () => {
      try {
        setHandling(true);
        const result = await backendService.post<Log[]>('/api/logs', {
          unit,
          type,
        });
        if (result.kind === 'ok') {
          setLogs(result.data.reverse());
        } else {
          notification.warning({
            message: t(translations.errors.queryFailed).toString(),
            description: formatError(result),
          });
        }
      } finally {
        setHandling(false);
      }
    })();
  }, [t, type, unit]);

  useEffect(() => {
    if (_.size(customFilter)) {
      let rawLogsFilter: Log[] = logs;
      if (customFilter.user) {
        rawLogsFilter = rawLogsFilter.filter((log) => log.user?.includes(customFilter.user ?? ''));
      }
      if (customFilter.rangeTime) {
        const [dayStart, dayEnd] = customFilter.rangeTime;
        rawLogsFilter = rawLogsFilter.filter(
          (log) =>
            dayStart?.startOf('day').valueOf() <= log.time &&
            log.time <= dayEnd?.endOf('day').valueOf()
        );
      }
      if (customFilter.action) {
        switch (customFilter.action) {
          case 'addMail':
            rawLogsFilter = rawLogsFilter.filter(({ message }) => message.includes('Thêm email'));
            break;
          case 'removeMail':
            rawLogsFilter = rawLogsFilter.filter(({ message }) => message.includes('Xoá email'));
            break;
          case 'license':
            rawLogsFilter = rawLogsFilter.filter(
              ({ message }) => message.includes('license') || message.includes('storage')
            );
            break;
          default:
            break;
        }
      }
      setLogsFilter(rawLogsFilter);
    } else {
      setLogsFilter(logs);
    }
  }, [customFilter, logs]);

  return (
    <Modal
      title={
        <div>
          Logs
          <Form form={form} style={{ marginTop: 10 }}>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item name="user" style={{ marginBottom: 0 }}>
                  <Input placeholder="Người thực hiện" />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item name="action" style={{ marginBottom: 0 }}>
                  <Select placeholder="Hành động" options={actionOptions} />
                </Form.Item>
              </Col>
              <Col span={1} style={{ fontSize: 18, padding: 5, color: '#1890ff' }}>
                <FilterFilled
                  onClick={() => {
                    const filter = form.getFieldsValue();
                    setCustomFilter(filter);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={23}>
                <Form.Item name="rangeTime">
                  <DatePicker.RangePicker
                    style={{ width: '100%' }}
                    format="D/M/Y"
                    placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      }
      visible
      onCancel={handleCancel}
      bodyStyle={{ maxHeight: window.innerHeight - 250, overflow: 'auto' }}
      footer={null}
    >
      {handling ? <Waiting /> : null}
      <Timeline mode="left">
        {logsFilter.map(({ time, message, user }) => (
          <Timeline.Item key={time}>
            <Typography.Text type="secondary">
              {user} - {moment(time).format('D/M/Y hh:mm')}
            </Typography.Text>
            <Typography.Paragraph>
              {message.split('\n').map((detail) => (
                <p style={{ marginBottom: 0 }}>{detail}</p>
              ))}
            </Typography.Paragraph>
          </Timeline.Item>
        ))}
      </Timeline>
    </Modal>
  );
};

export default Logs;
