import { Layout, notification } from 'antd';
import AuthorizedRoute from 'AuthorizedRoute';
import { Waiting } from 'components';
import { useResources } from 'features/catalogs/hooks';
import { useProducts } from 'features/products/hooks';
import { useUserSlice } from 'features/user/store';
import { selectUserAuthorized, selectUserInformation } from 'features/user/store/selectors';
import {
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink,
} from 'firebase/auth';
import { lazy, Suspense, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const Header = lazy(() => import('features/layout/Header'));
const Sider = lazy(() => import('features/layout/Sider'));
const NotFoundPage = lazy(() => import('pages/NotFound'));
const LoginPage = lazy(() => import('pages/Login'));
const HomePage = lazy(() => import('pages/Home'));
const ProductPage = lazy(() => import('pages/Product'));
const AccountsPage = lazy(() => import('pages/Accounts'));
const StaffsPage = lazy(() => import('pages/Staffs'));
const UnitsPage = lazy(() => import('pages/Units'));
const FoodsPage = lazy(() => import('pages/Foods'));
const CitiesPage = lazy(() => import('pages/Cities'));
const NotificationsPage = lazy(() => import('pages/Notifications'));
const CreateVotePage = lazy(() => import('pages/CreateVote'));
const KudosReportPage = lazy(() => import('pages/KudosReport'));

function App() {
  const dispatch = useDispatch();
  const authorized = useSelector(selectUserAuthorized);
  const userInformation = useSelector(selectUserInformation);
  const { actions } = useUserSlice();

  const { isLoading: isResourcesLoading } = useResources();
  const { isLoading: isProductsLoading } = useProducts();

  useEffect(() => {
    if (isSignInWithEmailLink(getAuth(), window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        notification.warning({
          message: 'Link không hợp lệ',
          description: 'Link đã được mở trên một thiết bị khác. Xin vui lòng đăng nhập lại',
        });
      } else {
        signInWithEmailLink(getAuth(), email, window.location.href)
          .then(() => {
            window.localStorage.removeItem('emailForSignIn');
          })
          .catch((err) => {
            notification.error({
              message: 'Lỗi',
              description: err.message,
            });
          });
      }
    }
    return onAuthStateChanged(getAuth(), (user) => {
      dispatch(
        actions.signedIn(
          user?.uid && user?.email
            ? {
                uid: user.uid,
                email: user.email,
                displayName: user?.displayName ?? '',
                photoURL: user?.photoURL ?? '',
              }
            : null
        )
      );
    });
  }, [actions, dispatch]);

  const signedIn = useMemo(() => Boolean(userInformation?.email), [userInformation]);

  if (isResourcesLoading || isProductsLoading || !authorized) return <Waiting />;

  return (
    <BrowserRouter>
      <Suspense fallback={<Waiting />}>
        <Layout>
          {signedIn ? <Sider /> : null}
          <Layout>
            {signedIn ? <Header /> : null}
            <Layout.Content>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/staffs"
                  element={
                    <AuthorizedRoute>
                      <StaffsPage />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="/units"
                  element={
                    <AuthorizedRoute>
                      <UnitsPage />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="/accounts"
                  element={
                    <AuthorizedRoute>
                      <AccountsPage />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="/products/:product"
                  element={
                    <AuthorizedRoute>
                      <ProductPage />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="/notifications"
                  element={
                    <AuthorizedRoute>
                      <NotificationsPage />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="/catalogs/foods"
                  element={
                    <AuthorizedRoute>
                      <FoodsPage />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="/catalogs/cities"
                  element={
                    <AuthorizedRoute>
                      <CitiesPage />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="/bot/create-vote"
                  element={
                    <AuthorizedRoute>
                      <CreateVotePage />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="/bot/kudos-report"
                  element={
                    <AuthorizedRoute>
                      <KudosReportPage />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="/"
                  element={
                    <AuthorizedRoute>
                      <HomePage />
                    </AuthorizedRoute>
                  }
                />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Layout.Content>
          </Layout>
        </Layout>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
